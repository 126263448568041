import React from "react";
import {graphql} from "gatsby";
import PageParallax from "../components/page-parallax";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql `
query NotFoundPageQuery {
  sanitySiteSettings {
    notFoundText
    notFoundImage {
      ...Image
    }
  }
}
`;

const NotFoundPage = ({data}) => (
    <Layout>
        <SEO title="404: Not found" image={data.sanitySiteSettings.notFoundImage}/>
        <section id="not-found">
            <PageParallax image={data.sanitySiteSettings.notFoundImage} title="NOT FOUND" subtitle={data.sanitySiteSettings.notFoundText}/>
        </section>
    </Layout>
);

export default NotFoundPage;
